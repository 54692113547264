import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, get, set } from 'firebase/database';
import { auth, database } from '../firebaseConfig'; // No need to import firebaseConfig directly
import Loader from '../Components/Loader'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userRef = ref(database, `3I_Consulting/users/${firebaseUser.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUser({ uid: firebaseUser.uid, email: firebaseUser.email, username: userData.username });
        } else {
          setUser({ uid: firebaseUser.uid, email: firebaseUser.email, username: firebaseUser.email.split('@')[0] });
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

//   const register = async (username, email, password) => {
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       const firebaseUser = userCredential.user;

//       // Save user data to the Realtime Database
//       const userRef = ref(database, `3I_Consulting/users/${firebaseUser.uid}`);
//       await set(userRef, { username, email });

//       setUser({ uid: firebaseUser.uid, email: firebaseUser.email, username });
//     } catch (error) {
//       console.error('Registration error:', error);
//       throw new Error('Error registering user');
//     }
//   };

  const login = async (emailOrUsername, password) => {
    try {
      let email = emailOrUsername;

      if (!emailOrUsername.includes('@')) {
        // Assume emailOrUsername is a username and find the corresponding email
        const usersRef = ref(database, '3I_Consulting/users');
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const users = snapshot.val();
          const userEntry = Object.values(users).find(user => user.username === emailOrUsername);
          if (userEntry) {
            email = userEntry.email;
          } else {
            console.log('User not found');
            return null;
          }
        }
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;

      const userRef = ref(database, `3I_Consulting/users/${firebaseUser.uid}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        return { uid: firebaseUser.uid, email: firebaseUser.email, username: userData.username };
      }

      return { uid: firebaseUser.uid, email: firebaseUser.email, username: firebaseUser.email.split('@')[0] };
    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Error connecting to database');
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {loading ? <Loader/> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
